<template>
  <block-action
    :block-action="blockAction"
    :style="{
      padding: blockPadding
    }"
    class="hero__wrapper p-relative d-block"
  >
    <template v-slot="{ isLocked, isLoadingAccess, availableAfter }">
      <div
        :class="[
          {
            '--playing': videoPlaying,
            '--percent': isPercentHeight,
            '--full': styles.width === HERO_IMAGE_WIDTH_OPTIONS.FULL
          },
          'hero p-relative',
          backgroundColor ? `--bg-${backgroundColor}` : null
        ]"
        :style="[
          {
            '--hero-height': imageHeight,
            borderRadius: blockBorderRadius,
            boxShadow: styles.boxShadow
          }
        ]"
      >
        <div
          v-if="!videoPlaying"
          :data-lightbox-image="allowToOpenLightbox"
          data-gallery="hero-gallery"
          :data-lightbox-group="allowToOpenLightbox ? LIGHT_BOX_KEY : null"
          :data-src="backgroundImageUrl"
          :style="{
            backgroundImage: backgroundImageUrl
              ? `url(${backgroundImageUrl})`
              : null,
            backgroundSize: styles.size,
            backgroundPosition: backgroundPosition,
            opacity: imageOpacity
          }"
          class="p-absolute hero__background"
        >
          <div
            v-if="image && image.blurhash"
            :data-role="`blur-hash-wrapper-${_uid}`"
            :class="[{ '--hide': hasLoadedImage }, 'hero__blurhash p-overlay']"
          >
            <blur-hash-canvas
              :hash="image.blurhash"
              :height="blurHashSize.height"
              :width="blurHashSize.width"
            />
          </div>
        </div>
        <div
          :class="[
            {
              '--shadow': overlayShadow,
              '--audio': hasAudio
            },
            `--y-${styles.textPositionY}`,
            `--x-${styles.textPositionX}`,
            'hero__overlay d-flex h-100 w-100',
            'hero__shadow'
          ]"
        >
          <video-embed
            v-if="hasVideo"
            :video="video"
            :thumbnail="getImageUrl(image)"
            :thumbnail-size="styles.size"
            :thumbnail-position="backgroundPosition"
            :thumbnail-background-color="backgroundColor"
            :autoplay="videoOptions.autoplay || false"
            :hide-controls="videoOptions.hideControls || false"
            :auto-initialize="videoOptions.autoInitialize || false"
            :plays-inline="videoOptions.playsInline"
            :portrait="videoOptions.portrait || false"
            :muted="videoOptions.autoplay || videoOptions.muted || false"
            :loop="videoOptions.loop || false"
            :cover="videoOptions.cover || false"
            :use-persistent-mute-status="
              videoOptions.usePersistentMuteStatus || false
            "
            :disable-fullscreen="videoOptions.disableFullscreen || false"
            :finished-action="videoFinishedAction"
            :opacity="imageOpacity"
            @ready="e => $emit('videoReady', e)"
            @play="() => (videoPlaying = true)"
            @pause="() => (videoPlaying = false)"
            @stop="() => (videoPlaying = false)"
          />

          <inline-audio-player
            v-else-if="hasAudio"
            class="hero__audio p-absolute w-100"
            :audio="audio"
            :key="audioLoadedKey"
            :autoplay="audioOptions.autoplay || false"
            :loop="audioOptions.loop || false"
            :thumbnail="getImageUrl(image)"
          />

          <transition name="fade">
            <slot
              v-if="!(videoPlaying && !videoOptions.autoplay)"
              :height="imageHeight"
            />
          </transition>
        </div>

        <template v-if="isLocked || isLoadingAccess">
          <access-lock
            overlay
            :is-loading="isLoadingAccess"
            :available-after="availableAfter"
          />
        </template>
      </div>
    </template>
  </block-action>
</template>

<script>
import { BlurHashCanvas, shouldShow, calcSize } from "@/lib/blurhash"
import {
  BASE_WIDTH,
  BLOCK_POSITIONS,
  HERO_IMAGE_DISPLAY_OPTIONS,
  HERO_IMAGE_WIDTH_OPTIONS
} from "@shared/blocks"

import { isPresent, getImageUrl } from "@/lib/utils"
import { BLOCK_DEFAULT_HEIGHT_RATIO, BLOCK_ACTIONS } from "@/lib/stream-utils"

import { appHeight } from "@/lib/app-height-watcher"
import { LIGHT_BOX_KEY } from "@/lib/constants"

import {
  withRatio,
  toPadding,
  ratioToMultiplier,
  BLOCK_BASE_HEIGHT,
  BORDER_RADIUS_BASE,
  HEIGHT_UNITS,
  HERO_HEIGHT_RATIO_RANGE
} from "@shared/block-ratio"

import BlockAction from "@/components/BlockAction"

const DEFAULT_STYLE = {
  size: HERO_IMAGE_DISPLAY_OPTIONS.COVER, // cover, contain
  width: HERO_IMAGE_WIDTH_OPTIONS.CONTAIN, // contain, full
  padding: "0rem",
  boxShadow: null,
  heightRatio: BLOCK_DEFAULT_HEIGHT_RATIO,
  heightRatioUnit: "pixel",
  baseHeight: BLOCK_BASE_HEIGHT,
  borderRadiusRatio: 0,
  textPositionX: BLOCK_POSITIONS.START,
  textPositionY: BLOCK_POSITIONS.END,
  imagePositionX: BLOCK_POSITIONS.CENTER,
  imagePositionY: BLOCK_POSITIONS.CENTER
}

const CONTAINER_MAX_WIDTH = 706

export default {
  components: {
    BlockAction,
    BlurHashCanvas,
    AccessLock: () => import("@/components/AccessLock"),
    VideoEmbed: () => import("@/components/VideoEmbed"),
    InlineAudioPlayer: () => import("@/components/audio/InlinePlayer")
  },

  props: {
    image: { type: [Object, String], default: () => ({}) },
    styleOptions: {
      type: Object,
      default: () => DEFAULT_STYLE
    }, // center, top_left, top_right, bottom_left,
    video: { type: Object, default: null },
    audio: { type: Object, default: null },
    videoOptions: { type: Object, default: () => ({}) },
    // { autoplay: Boolean, hideControls: Boolean, playsInline: Boolean, usePersistentMuteStatus: Boolean }
    audioOptions: { type: Object, default: () => ({}) },
    // { autoplay: Boolean, loop: Boolean }
    overlayShadow: { type: Boolean, default: true },
    spaceXRatio: {
      type: Number,
      default: 0
    },
    spaceTopRatio: {
      type: Number,
      default: 0
    },
    spaceBottomRatio: {
      type: Number,
      default: 0
    },
    imageOpacityRatio: {
      type: Number,
      default: 100
    },
    blockAction: { type: Object, default: null },
    videoFinishedAction: { type: Object, default: null },
    backgroundColor: { type: String, default: null }
  },

  data() {
    return {
      blurHashSize: { width: 500, height: 500 },
      videoPlaying: false,
      hasLoadedImage: false,
      audioLoadedKey: 1,
      LIGHT_BOX_KEY,
      HERO_IMAGE_WIDTH_OPTIONS
    }
  },

  computed: {
    allowToOpenLightbox() {
      return (
        this.blockAction?.action === BLOCK_ACTIONS.OPEN_LIGHTBOX &&
        this.hasImage &&
        !this.hasVideo
      )
    },

    backgroundImageUrl() {
      return this.hasImage ? getImageUrl(this.image) : null
    },

    hasImage() {
      return isPresent(this.image)
    },

    hasVideo() {
      return isPresent(this.video)
    },

    hasAudio() {
      return isPresent(this.audio)
    },

    hasContent() {
      return this.hasImage || this.hasVideo
    },

    shouldShowVideo() {
      return (!this.hasImage && this.hasVideo) || this.videoPlaying
    },

    shouldShowBlurHash() {
      return shouldShow(this.image)
    },

    styles() {
      const result = { ...DEFAULT_STYLE }

      for (const style in this.styleOptions) {
        const value = this.styleOptions[style]
        if (value || value === 0) {
          result[style] = value
        }
      }

      return result
    },

    backgroundPosition() {
      if (this.styles.size === HERO_IMAGE_DISPLAY_OPTIONS.CONTAIN)
        return `${BLOCK_POSITIONS.CENTER} ${BLOCK_POSITIONS.CENTER}`

      const cssPositionX = {
        [BLOCK_POSITIONS.START]: "left",
        [BLOCK_POSITIONS.END]: "right"
      }

      const cssPositionY = {
        [BLOCK_POSITIONS.START]: "top",
        [BLOCK_POSITIONS.END]: "bottom"
      }

      return `${cssPositionX[this.styles.imagePositionX] ||
        BLOCK_POSITIONS.CENTER} ${cssPositionY[this.styles.imagePositionY] ||
        BLOCK_POSITIONS.CENTER}`
    },

    isPercentHeight() {
      return this.styles.heightRatioUnit === HEIGHT_UNITS.PERCENT
    },

    responsiveScaleRatio() {
      return (
        parseInt(
          withRatio(100, this.styles.heightScaleRatio || 0, { unit: null })
        ) / 100
      )
    },

    imageHeight() {
      if (this.isPercentHeight) {
        return `calc(var(--app-height-initial) * ${ratioToMultiplier(
          this.styles.heightRatio,
          HERO_HEIGHT_RATIO_RANGE.MIN,
          HERO_HEIGHT_RATIO_RANGE.MAX
        )})`
      } else {
        const heroHeight = parseInt(
          withRatio(this.styles.baseHeight, this.styles.heightRatio, {
            unit: null,
            integer: true
          })
        )

        const ratio = BASE_WIDTH / heroHeight

        const newHeight = Math.max(
          Math.min(appHeight.width, CONTAINER_MAX_WIDTH) / ratio,
          heroHeight
        )
        const newDifference = newHeight - heroHeight

        return heroHeight + newDifference * this.responsiveScaleRatio + "px"
      }
    },

    blockBorderRadius() {
      return withRatio(BORDER_RADIUS_BASE, this.styles.borderRadiusRatio, {
        integer: true
      })
    },

    blockPadding() {
      return toPadding(
        this.spaceTopRatio,
        this.spaceXRatio,
        this.spaceBottomRatio
      )
    },

    imageOpacity() {
      return withRatio(1, this.imageOpacityRatio, { unit: null })
    }
  },

  watch: {
    audio() {
      // Re-render audio embed when the audio is changed
      if (isPresent(this.audio)) {
        this.audioLoadedKey++
      }
    },

    "image.image.url": {
      immediate: true,
      handler: function() {
        this.hasLoadedImage = false

        const img = new Image()

        img.addEventListener("load", () => {
          this.hasLoadedImage = true
        })

        img.src = getImageUrl(this.image)

        // Ensure it will show
        setTimeout(() => {
          if (!this.hasLoadedImage) {
            this.hasLoadedImage = true
          }
        }, 5000)
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.setBlurHashSize()
    })
  },

  methods: {
    getImageUrl,
    withRatio,

    setBlurHashSize() {
      if (this.shouldShowBlurHash) {
        this.blurHashSize = calcSize(
          document.querySelector(`[data-role="blur-hash-wrapper-${this._uid}"]`)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.hero {
  @include app-background;
}
</style>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";
@import "@/assets/styles/mk-lightbox";

.hero {
  &:not(.--full) {
    @include container;
  }

  width: 100%;
  overflow: hidden;
  min-height: 200px; // Fallback
  min-height: var(--hero-height);
  display: flex;
  flex-direction: column;

  &:not(.--percent) {
    min-height: var(--hero-height);
  }

  .blocks-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: $video--controls-z-index - 2;

    .block {
      width: 100%;
    }

    .v-button,
    .v-link {
      position: relative;
      z-index: 5;
    }
  }

  [data-lightbox-image] + .hero__overlay {
    [data-role="block-container"] {
      cursor: pointer;
    }
  }
}

.hero__overlay,
.hero__background {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.hero__background {
  transition: opacity 0.1s ease;
  background-repeat: no-repeat;
}

.hero__blurhash {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  transition: opacity 1s ease-out;

  &.--hide {
    opacity: 0;
  }
}

.hero__audio {
  bottom: 0;
}

.hero__overlay {
  flex-grow: 1;

  &.--shadow {
    position: relative;
    background: linear-gradient(
      to bottom,
      rgba($color-fixed-rgb--black, 0) 60%,
      rgba($color-fixed-rgb--black, 0.32)
    );
  }

  &.--audio {
    .blocks-container {
      padding-bottom: 116px; // audio player height
    }
  }

  &.--x-start {
    .blocks-container {
      text-align: left;
      align-items: flex-start;
    }
  }

  &.--x-center {
    .blocks-container {
      text-align: center;
      align-items: center;
    }
  }

  &.--x-end {
    .blocks-container {
      text-align: right;
      align-items: flex-end;
    }
  }

  &.--y-start {
    .blocks-container {
      & > .block:last-child {
        margin-bottom: auto;
      }
    }
  }

  &.--y-center {
    .blocks-container {
      & > .block:first-child {
        margin-top: auto;
      }

      & > .block:last-child {
        margin-bottom: auto;
      }
    }
  }

  &.--y-end {
    .blocks-container {
      & > .block:first-child {
        margin-top: auto;
      }
    }
  }
}

.hero__spacer {
  &.--no-content {
    height: $size--10;
    background: white;
  }
}

.app-page
  > [data-transparent-toolbar] // Only apply to transparent toolbars
  + .blocks-container // Sibling blocks container
  > .block[data-type="hero_block"]:first-child // Only first hero block
 {
  .hero {
    &:not(.--percent) {
      min-height: calc(var(--hero-height) + env(safe-area-inset-top));

      .blocks-container {
        margin-top: env(safe-area-inset-top);
      }
    }
  }
}
</style>
