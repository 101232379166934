var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('block-action',{staticClass:"hero__wrapper p-relative d-block",style:({
    padding: _vm.blockPadding
  }),attrs:{"block-action":_vm.blockAction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isLocked = ref.isLocked;
  var isLoadingAccess = ref.isLoadingAccess;
  var availableAfter = ref.availableAfter;
return [_c('div',{class:[
        {
          '--playing': _vm.videoPlaying,
          '--percent': _vm.isPercentHeight,
          '--full': _vm.styles.width === _vm.HERO_IMAGE_WIDTH_OPTIONS.FULL
        },
        'hero p-relative',
        _vm.backgroundColor ? ("--bg-" + _vm.backgroundColor) : null
      ],style:([
        {
          '--hero-height': _vm.imageHeight,
          borderRadius: _vm.blockBorderRadius,
          boxShadow: _vm.styles.boxShadow
        }
      ])},[(!_vm.videoPlaying)?_c('div',{staticClass:"p-absolute hero__background",style:({
          backgroundImage: _vm.backgroundImageUrl
            ? ("url(" + _vm.backgroundImageUrl + ")")
            : null,
          backgroundSize: _vm.styles.size,
          backgroundPosition: _vm.backgroundPosition,
          opacity: _vm.imageOpacity
        }),attrs:{"data-lightbox-image":_vm.allowToOpenLightbox,"data-gallery":"hero-gallery","data-lightbox-group":_vm.allowToOpenLightbox ? _vm.LIGHT_BOX_KEY : null,"data-src":_vm.backgroundImageUrl}},[(_vm.image && _vm.image.blurhash)?_c('div',{class:[{ '--hide': _vm.hasLoadedImage }, 'hero__blurhash p-overlay'],attrs:{"data-role":("blur-hash-wrapper-" + _vm._uid)}},[_c('blur-hash-canvas',{attrs:{"hash":_vm.image.blurhash,"height":_vm.blurHashSize.height,"width":_vm.blurHashSize.width}})],1):_vm._e()]):_vm._e(),_c('div',{class:[
          {
            '--shadow': _vm.overlayShadow,
            '--audio': _vm.hasAudio
          },
          ("--y-" + (_vm.styles.textPositionY)),
          ("--x-" + (_vm.styles.textPositionX)),
          'hero__overlay d-flex h-100 w-100',
          'hero__shadow'
        ]},[(_vm.hasVideo)?_c('video-embed',{attrs:{"video":_vm.video,"thumbnail":_vm.getImageUrl(_vm.image),"thumbnail-size":_vm.styles.size,"thumbnail-position":_vm.backgroundPosition,"thumbnail-background-color":_vm.backgroundColor,"autoplay":_vm.videoOptions.autoplay || false,"hide-controls":_vm.videoOptions.hideControls || false,"auto-initialize":_vm.videoOptions.autoInitialize || false,"plays-inline":_vm.videoOptions.playsInline,"portrait":_vm.videoOptions.portrait || false,"muted":_vm.videoOptions.autoplay || _vm.videoOptions.muted || false,"loop":_vm.videoOptions.loop || false,"cover":_vm.videoOptions.cover || false,"use-persistent-mute-status":_vm.videoOptions.usePersistentMuteStatus || false,"disable-fullscreen":_vm.videoOptions.disableFullscreen || false,"finished-action":_vm.videoFinishedAction,"opacity":_vm.imageOpacity},on:{"ready":function (e) { return _vm.$emit('videoReady', e); },"play":function () { return (_vm.videoPlaying = true); },"pause":function () { return (_vm.videoPlaying = false); },"stop":function () { return (_vm.videoPlaying = false); }}}):(_vm.hasAudio)?_c('inline-audio-player',{key:_vm.audioLoadedKey,staticClass:"hero__audio p-absolute w-100",attrs:{"audio":_vm.audio,"autoplay":_vm.audioOptions.autoplay || false,"loop":_vm.audioOptions.loop || false,"thumbnail":_vm.getImageUrl(_vm.image)}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!(_vm.videoPlaying && !_vm.videoOptions.autoplay))?_vm._t("default",null,{"height":_vm.imageHeight}):_vm._e()],2)],1),(isLocked || isLoadingAccess)?[_c('access-lock',{attrs:{"overlay":"","is-loading":isLoadingAccess,"available-after":availableAfter}})]:_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }